import React, { Fragment } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { css } from "@emotion/react"
import { CssNotMobile } from "../components/utils/responsive"
import { content_frame_desktop, frame_common } from "../components/utils/css"
import loadable from "@loadable/component"
const Kaereba = loadable(() => import("../components/kaereba"))

const TITLE = "KAEREBA for MDX"
class KaerebaForMDX extends React.Component {
  render() {
    return (
      <Fragment>
        <Layout location={this.props.location}>
          <Seo title={`${TITLE}`} slug={"/kaereba"} />
          <section
            css={css`
              ${frame_common};
              ${CssNotMobile(content_frame_desktop)};
            `}
          >
            <Kaereba />
          </section>
        </Layout>
      </Fragment>
    )
  }
}

export default KaerebaForMDX
